// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import SectionWrapper from '../components/SectionWrapper';
import PageHelmet from '../plugins/helmet/components/PageHelmet';
import RichText from '../plugins/prismic/components/RichText';
import type { Query } from '../schema.flow';

const styles = () => ({
  root: {},
  wrapper: {},
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textAlign: 'left',
    margin: 'auto',
  },
  capitalText: {
    textTransform: 'uppercase',
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles>>]: string },
  data: Query,
};

const CookiesPage = ({ classes, data: query }: Props) => (
  <SectionWrapper
    limitWidth
    guttersH
    guttersV
    classes={{ root: classes.root, wrapper: classes.wrapper }}
  >
    <PageHelmet
      data={query.prismicCookieConsent && query.prismicCookieConsent.data}
    />
    <div className={classes.container}>
      {query.prismicCookieConsent?.data?.page_title?.text ? (
        <Typography variant="h1" className={classes.capitalText}>
          {query.prismicCookieConsent?.data?.page_title?.text}
        </Typography>
      ) : null}
      {RichText.hasValue(query.prismicCookieConsent?.data?.page_description) ? (
        <Typography
          component="div"
          variant="body1"
          gutterBottom
        >
          <RichText {...query.prismicCookieConsent?.data?.page_description} />
        </Typography>
      ) : null}
    </div>
  </SectionWrapper>
);

export default withStyles(styles)(CookiesPage);

export const query = graphql`
  query CookiesPageQuery($prismicLocaleId: String) {
    prismicCookieConsent(lang: { eq: $prismicLocaleId }) {
      id
      lang
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        page_title {
          text
        }
        page_description {
          text
          html
        }
      }
    }
  }
`;
